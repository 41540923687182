import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import MachineNavigation from './MachineNavigation'

export default function ProductSupport(props) {
    let machine = props.pageContext
    return (
        <Layout>
            <MachineNavigation machine={machine} page="support"/>
            <PaddedLayout>
            <h4>{machine.model} </h4>
            <h2>Support</h2>
            < br />
            <Link to="">Register Your Machine</Link>
            <Link to="">FAQ</Link>
            <Link to="">Parts Shop</Link>
            <Link to="">Downloads</Link>
            <Link to="">Maintenance Guides</Link>
            </PaddedLayout>
        </Layout>
    )
}
