import React from 'react'
import { Link } from 'gatsby'
import './machineNavigation.scss'
import { RiArrowDropDownLine } from 'react-icons/ri'

export default function MachineNavigation(props) {
    let machine= props.machine
    let page = props.page
    let active = "Overview"
    return (
        <div className="machine-heading">
          <div className="machine-header">
          </div>
          <div className="machine-navigation-desktop">
            <ul>
                <li className="model-name last">{machine.model}</li>
                <li><Link to={`/models/${machine.model}/overview`}  className={page === "overview" ? "active": ""}>Overview</Link></li>
                <li><Link to={`/models/${machine.model}/features`} className={page === "features" ? "active": ""}>Features</Link></li>
                <li><Link to={`/models/${machine.model}/gallery`} className={page === "gallery" ? "active": ""}>Gallery</Link></li>
                <li><Link to={`/models/${machine.model}/specifications`} className={page === "specifications" ? "active": ""}>Specifications</Link></li>
                <li><Link to={`/models/${machine.model}/support`} className={page === "support" ? "active": ""}>Support</Link></li>
                <li className="last"><Link to="">Request a Demo</Link></li>
            </ul>
          </div>
          <div className="machine-navigation-mobile">
          <div><span className="model-name">{machine.model}</span></div>
          <div>
          <ul className="mobile-sub-dropdown">
                <li className="dropdown-link">Overview <RiArrowDropDownLine />
                <ul className="sub-drop">
                <li><Link to={`/models/${machine.model}/overview`} className={page === "overview" ? "active": ""}>Overview</Link></li>
                <li><Link to={`/models/${machine.model}/features`} className={page === "features" ? "active": ""}>Features</Link></li>
                <li><Link to={`/models/${machine.model}/gallery`} className={page === "gallery" ? "active": ""}>Gallery</Link></li>
                <li><Link to={`/models/${machine.model}/specifications`} className={page === "specifications" ? "active": ""}>Specifications</Link></li>
                <li><Link to={`/models/${machine.model}/support`} className={page === "support" ? "active": ""}>Support</Link></li>
                <li className="last"><Link to="">Request a Demo</Link></li>
                </ul>
                </li>

            </ul>
          </div>

          </div>
        </div>
    )
}
